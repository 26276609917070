import type { ComputedRef } from 'vue';
import type { RouteLocationRaw } from 'vue-router';

type AccountMenuItem = {
    label: string;
    icon: string;
    to: RouteLocationRaw;
};

export const useAccountMenuItems = (): ComputedRef<AccountMenuItem[]> => {
    const localePath = useLocalePath();
    const { tt } = useTypedI18n();

    return computed<AccountMenuItem[]>(() => [
        {
            label: tt('account.bids.title'),
            icon: 'i-mdi-timer-sand',
            to: localePath('account-bids'),
        },
        {
            label: tt('account.sales.title'),
            icon: 'i-mdi-currency-eur',
            to: localePath('account-sales'),
        },
        {
            label: tt('common.profile'),
            icon: 'i-mdi-account-circle-outline',
            to: localePath('account-profile'),
        },
    ]);
};
